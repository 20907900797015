import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import { ProfileService } from './profile.service';
import { PubSubService } from './pub-sub.service';

import { AccountServiceStub } from '@weavix/services/src/account.service';
import { isAttachmentGif } from 'crews/app/radio/channel-message-attachment/channel-message-attachment.component';
import {
    Channel, ChannelAlerts, ChannelMessage, ChannelMessageQuery, ChannelReadResponse,
    ChannelType,
    ChannelTyper, ChannelTypers, ChannelTyperStatus,
    DirectMessage, LinkPreviewRequest, MessageAttachment, MessageAttachmentType, URLLinkPreview,
} from '../models/channels.model';

import { Communication } from '@weavix/models/src/channel/channel-message';
import { ChannelServiceStub } from '@weavix/services/src/channel.service';
import { Person } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';

@Injectable()
export class ChannelService extends ChannelServiceStub {

    constructor(
        private httpService: HttpService,
        private accountService: AccountServiceStub,
        private profileService: ProfileService,
        private pubSubService: PubSubService,
        private alertsService: AlertService,
    ) {
        super();
    }

    get visibility() { return this.isVisible; }
    set setVisiblity(isVisible: boolean) { this.isVisible = isVisible; }
    typers: {[id: string]: ChannelTyper[]};

    selectChannel: BehaviorSubject<{id: string}> = new BehaviorSubject({ id: '' });
    hideChannelsList: BehaviorSubject<boolean> = new BehaviorSubject(false);
    channelIdNameMap: {[key: string]: string} = {};
    isAttachmentGif = isAttachmentGif;

    private url: string = '/core/channels';

    private isVisible: boolean = false;
    private accountUrl = (channel: Channel) => channel.accountId ? `/a/${channel.accountId}/core/channels` : '/core/channels';

    async buildChannelMap(component) {
        const lists = await this.getChannels(component);
        this.channelIdNameMap = {};
        lists.forEach(l => this.channelIdNameMap[l.id] = l.name);
    }

    toggleVisibility(): void {
        this.setVisiblity = !this.isVisible;
    }

    getChannels(component, facilityId?: string, tags?) {
        return this.httpService.get<Channel[]>(component, this.url, { facilityId, tags }, null, null);
    }

    getAllChannels(component) {
        return this.httpService.get<Channel[]>(component, '/core/me/channels', undefined, null, null);
    }

    getSubscribedPeople(component, channel: Channel) {
        return this.httpService.get<Person[]>(component, `${this.accountUrl(channel)}/${channel.id}/subscribed`, undefined, null, (person: Person) => person.fullName);
    }

    getChannel(component, id: string) {
        return this.httpService.get<Channel>(component, `${this.url}/${id}`, null);
    }

    addChannel(component, channel: Channel) {
        return this.httpService.post<Channel>(component, `${this.url}`, channel);
    }

    addGroup(component, channel: Channel) {
        return this.httpService.post<Channel>(component, `${this.url}/groups`, channel);
    }

    updateChannel(component, channel: Channel, update?: Partial<Channel>) {
        channel = this.cleanTags(channel);
        if (channel.type === ChannelType.Channel) {
            return this.httpService.put<Channel>(component, `/a/${channel.accountId}${this.url}/${channel.id}`, update ?? channel);
        } else if (channel.type === ChannelType.People) {
            return this.httpService.put<Channel>(component, `${this.url}/groups/${channel.id}`, update ?? channel);
        }
    }

    private cleanTags(channel: Channel) {
        if (channel.tags) {
            channel.tags = channel.tags.filter(x => x !== undefined);
        }
        return channel;
    }

    updateChannelName(component, channel: Channel, name: string) {
        return this.httpService.put<Channel>(component, `${this.accountUrl(channel)}/${channel.type === ChannelType.People ? 'groups/' : ''}${channel.id}`, { name: name || '' });
    }

    saveChannel(component, channel: Channel) {
        return channel.id ? this.updateChannel(component, channel) : this.addChannel(component, channel);
    }

    deleteChannel(component, id: string) {
        return this.httpService.delete<Channel>(component, `${this.url}/${id}`, null);
    }

    markChannelAsRead(component, channel: Channel) {
        return this.httpService.put<ChannelReadResponse>(component, `${this.accountUrl(channel)}/${channel.id}/preferences`, { read: true });
    }

    markChannelAsSnoozed(component, channel: Channel) {
        return this.httpService.put<ChannelReadResponse>(component, `${this.accountUrl(channel)}/${channel.id}/preferences`, { isSnoozed: true });
    }

    setTyping(component, channel: Channel, status: ChannelTyperStatus) {
        return this.httpService.put<ChannelReadResponse>(component, `${this.accountUrl(channel)}/${channel.id}/typing`, { status });
    }

    updateNotificationType(component, channel: Channel, notification: ChannelAlerts) {
        return this.httpService.put(component, `${this.accountUrl(channel)}/${channel.id}/preferences`, { notification });
    }

    pageChannel(component, channel: Channel) {
        return this.httpService.put(component, `${this.accountUrl(channel)}/${channel.id}/page`, {});
    }

    async getChannelMessages(component, channel: Channel, query?: ChannelMessageQuery) {
        return await this.httpService.get<ChannelMessage[]>(component, `${this.accountUrl(channel)}/${channel.id}/messages`, query, null, null);
    }

    addChannelMessage(component, channelMessage: DirectMessage, channel: Channel) {
        return this.httpService.post<ChannelMessage>(component, `${this.accountUrl(channel)}/${channel.id}/message`, channelMessage);
    }

    async getCommunications(component, facilityId: string, from: Date, to?: Date) {
        return this.httpService.get<Communication[]>(component, '/core/channels/communications', { facilityId, from, to });
    }

    async subscribeCommunications(component, facilityId: string) {
        const account = await this.accountService.getAccount();
        return this.pubSubService.subscribe<Communication>(component, Topic.AccountFacilityCommunication, [account.id, facilityId]);
    }

    async newAlertSubscription(component): Promise<Observable<ChannelMessage>> {
        const userProfile = await this.profileService.getUserProfile(component);
        if (userProfile) {
            return this.pubSubService.subscribe<ChannelMessage>(component, Topic.UserChannelMessageCreated, [userProfile.id])
                    .pipe(map(x => x.payload));
        }
        return of(null);
    }

    async subscribeTypers(component, channelId: string): Promise<Observable<ChannelTyper[]>> {
        const userProfile = await this.profileService.getUserProfile(component);
        return this.pubSubService.subscribe<ChannelTypers>(component, Topic.UserChannelTypers, [userProfile.id, channelId], true)
            .pipe(map(x => {
                return x.payload.typers;
            }));
    }

    async uploadMessageAttachments(component, channel: Channel, type: MessageAttachmentType, formData) {
        try {
            return await this.httpService.upload<MessageAttachment>(component, `${this.accountUrl(channel)}/${channel.id}/message/attachment/${type}`, formData);
        } catch (e) {
            if (e.error?.details?.reason) {
                const reason: string = e.error?.details?.reason;
                switch (reason) {
                    case ('file_too_large') :
                        this.alertsService.sendError(e, 'ERRORS.FILE.TOO-LARGE');
                        break;
                    case ('invalid_attachment_type') :
                        this.alertsService.sendError(e, 'ERRORS.CHANNEL.INVALID_ATTACHMENT_TYPE');
                        break;
                    case ('invalid_file_type') :
                        this.alertsService.sendError(e, 'ERRORS.CHANNEL.INVALID_FILE_TYPE');
                        break;
                    default :
                        this.alertsService.sendError(e, 'ERRORS.UPLOAD');
                }
            }
            throw (e);
        }
    }

    async getLinkPreviewInfo(component, urlRequest: LinkPreviewRequest) {
        return this.httpService.post<URLLinkPreview>(component, `/link-preview`, urlRequest);
    }

    async downloadAttachment(uri) {
        this.httpService.attachmentDownload(uri);
    }

    async setMqttClientSelectedChannel(component, channelId: string) {
        const postBody = {
            clientId: this.pubSubService.clientId,
            channelId,
        };
        return this.httpService.post<URLLinkPreview>(component, `/teams/client-connection-channel`, postBody);
    }
}
