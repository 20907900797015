import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';

import { MessageAttachment, MessageAttachmentType } from 'weavix-shared/models/channels.model';
import { PendingAttachment } from '../channels.component';

export function isAttachmentGif(attachment: MessageAttachment | PendingAttachment): boolean {
    return '.gif' === attachment?.file?.name.substr(attachment?.file?.name.length - 4);
}

export function processUploadUrl(url: string) {
    if (!url) return url;
    return url.replace(/http:\/\/localhost(:[0-9]+)?/, environment.is360Api);
}

@Component({
    selector: 'app-channel-message-attachment',
    templateUrl: './channel-message-attachment.component.html',
    styleUrls: ['./channel-message-attachment.component.scss'],
})
export class ChannelMessageAttachmentComponent implements OnInit {
    @Input() attachment: MessageAttachment | PendingAttachment;
    @Input() deletable: boolean = false;
    @Input() disableAttachment: boolean = false;
    @Input() pending: boolean = false;
    @Output() removeAttachment: EventEmitter<MessageAttachment> = new EventEmitter();

    @ViewChild('gif') private messageBoxRef: ElementRef;
    get gifElement() { return this.messageBoxRef?.nativeElement as HTMLImageElement; }

    attachmentType = MessageAttachmentType;
    teamsApp = environment.teamsApp;
    gifTimeout = null;
    isGif: boolean;
    closeIcon = 'fal fa-times';

    constructor() { }

    ngOnInit(): void {
        this.isGif = isAttachmentGif(this.attachment);
        this.attachment.thumbnailUri = processUploadUrl(this.attachment.thumbnailUri);
        if (this.attachment?.file?.uri) this.attachment.file.uri = processUploadUrl(this.attachment.file.uri);
    }

    async onGifClick() {
        if (this.gifTimeout) {
            this.gifElement.src = this.attachment.thumbnailUri;
            clearTimeout(this.gifTimeout);
            this.gifTimeout = null;
        } else {
            this.gifElement.src = this.attachment.file.uri;
            this.gifTimeout = setTimeout(() => {
                this.gifElement.src = this.attachment.thumbnailUri;
                this.gifTimeout = null;
            }, 15000);
        }
    }
}
