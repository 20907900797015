export interface IdentifyTraits {
    email: string;
    name: string;
    accountId: string;
}

export enum StProperty {
    accountId = 'accountId',
    action = 'action',
    component = 'component',
    count = 'count',
    dimensions = 'dimensions',
    endpoint = 'endpoint',
    errorData = 'errorData',
    id = 'id',
    method = 'method',
    object = 'object',
    page = 'page',
    query = 'query',
    source = 'source',
    type = 'type',
    unit = 'unit',
    version = 'version',
    visible = 'visible',
}

export type StProperties = { [key in StProperty]?: any };
export const allowedKeys = new RegExp((Object.keys(StProperty).join('|')), 'i');

export enum StObject {
    AccountSwitcher = 'AccountSwitcher',
    AnonymousUser = 'AnonymousUser',
    CallCamera = 'CallCamera',
    CallAudio = 'CallAudio',
    Channel = 'Channel',
    ChannelMessage = 'ChannelMessage',
    ChannelNotificationSettings = 'ChannelNotificationSettings',
    ChannelTagFilter = 'ChannelTagFilter',
    MapPlaybackTimeSelection = 'MapPlaybackTimeSelection',
    MessageAttachment = 'MessageAttachment',
    Tag = 'Tag',
    UserProfile = 'UserProfile',
    VoiceCall = 'VoiceCall',
    VideoCall = 'VideoCall',
    WilmaDetailPtt = 'WilmaDetailPtt',
    WilmaDetailSiren = 'WilmaDetailSiren',
    WilmaDetailBan = 'WilmaDetailBan',
    WilmaWidgetExpand = 'WilmaWidgetExpand',
    WilmaWidgetMap = 'WilmaWidgetMap',
    WilmaWidgetSiren = 'WilmaWidgetSiren',
    UserAvatar = 'UserAvatar',
}

export enum StAction {
    Admitted = 'Admitted',
    Canceled = 'Canceled',
    Cleared = 'Cleared',
    Clicked = 'Clicked',
    Copied = 'Copied',
    Created = 'Created',
    Deleted = 'Deleted',
    Disabled = 'Disabled',
    Enabled = 'Enabled',
    Ended = 'End',
    Joined = 'Joined',
    Left = 'Left',
    Messaged = 'Messaged',
    Moved = 'Moved',
    Rejected = 'Rejected',
    Removed = 'Removed',
    Resized = 'Resized',
    Set = 'Set',
    Started = 'Start',
    Subscribed = 'Subscribed',
    Toggled = 'Toggled',
    Unsubscribed = 'Unsubscribed',
    Updated = 'Updated',
    Uploaded = 'Uploaded',
}
