import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { LoggedInUser, UpdateUser, UserQrLogin } from '@weavix/models/src/user/User';
import { PermissionChecker } from '@weavix/permissions/src/permission-checker';
import { PermissionAction, PermissionApiResponse } from '@weavix/permissions/src/permissions.model';
import { ProfileServiceStub } from '@weavix/services/src/profile.service';
import { Person } from 'weavix-shared/models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { Account } from '../models/account.model';
import { ImageUpload } from '../models/upload.model';
import { AlertService } from './alert.service';
import { ACCOUNT_HEADER, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';
import { SyncNgService } from './sync/sync-ng-service';
import { TranslationService } from 'crews/app/core/services/translation/translation.service';

@Injectable()
export class ProfileService extends ProfileServiceStub {
    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
        private pubSubService: PubSubService,
        private syncService: SyncNgService,
        private translationService: TranslationService,
    ) {
        super();
    }

    userProfile: Promise<LoggedInUser> | LoggedInUser;
    private permissionCheckers: {[accountId: string]: PermissionChecker} = {};
    private accounts: Account[];
    profileUpdateSubject: Subject<LoggedInUser> = new Subject();
    permissionSub: Subscription[] = [];
    permissionChange$: Subject<Date> = new Subject<Date>();

    async getUserProfile(component, force: boolean = false): Promise<LoggedInUser> {
        if (this.userProfile && !force) return this.userProfile;
        this.userProfile = await this.getUserProfileImpl(component);
        return this.userProfile;
    }

    private async initAccountPermissionChecker(component, accountId: string, update: boolean = false): Promise<void> {
        const perms = await this.httpService.get<PermissionApiResponse>(component, `/a/${accountId}/core/me/account/permissions-v2`);
        this.permissionCheckers[accountId] = new PermissionChecker(perms);
        if (update) this.permissionChange$.next(new Date());
    }

    private async getUserProfileImpl(component) {
        try {
            await this.pubSubService.loggedIn();
            await this.syncService.onLoggedIn();

            const profile = await this.httpService.get<LoggedInUser>(component, '/core/me/user');
            this.accounts = profile.accounts;
            if (profile.locale) this.translationService.setLanguage(profile.locale);
            if (this.permissionSub.length > 0) await Promise.all(this.permissionSub.map(x => x.unsubscribe()));
            await Promise.all(this.accounts.map(async account => {
                await this.initAccountPermissionChecker(component, account.id);
                this.permissionSub.push(this.pubSubService.subscribe<Person>(component, Topic.AccountPersonPermissionsUpdated, [account.id, profile.id], false)
                    .subscribe(async () => {
                        this.initAccountPermissionChecker(component, account.id, true);
                    }));
            }));
            return profile;
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PROFILE.GET');
            this.alertsService.setAppLoading(false);
            console.error(e);

            return {
                id: null,
                avatarFile: null,
                firstName: null,
                lastName: null,
            };
        }
    }

    hasPermission(action: PermissionAction, facilityId?: string, folderId?: string, accountId: string = this.httpService.getAccountId()) {
        if (!this.permissionCheckers[accountId]) return false;
        return this.permissionCheckers[accountId].hasPermission(action, facilityId, folderId);
    }

    hasPermissionInAnyFacility(action: PermissionAction) {
        if (!Object.values(this.permissionCheckers).length) return false;
        return Object.values(this.permissionCheckers).some(x => x.hasAnyPermission(action));
    }

    hasPermissionInAnyAccountFacility(action: PermissionAction, accountId: string = this.httpService.getAccountId()) {
        if (!this.permissionCheckers[accountId]) return false;
        return this.permissionCheckers[accountId].hasAnyPermission(action);
    }

    isMaster() {
        return this.accounts?.some(x => x.master);
    }

    async updateUserProfile(component, id: string, update: UpdateUser) {
        try {
            await this.httpService.put(component, `/core/users/${id}`, update);
            this.userProfile = { ...(await this.userProfile), ...update };
            this.profileUpdateSubject.next(this.userProfile);
            return this.userProfile;
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PROFILE.UPDATE');
            this.alertsService.setAppLoading(false);

            throw (e);
        }
    }

    async uploadAvatar(component, fd: FormData): Promise<ImageUpload> {
        try {
            fd.append('mirrored', 'true');
            return await this.httpService.upload<ImageUpload>(component, '/core/uploads/avatar', fd);
        } catch (e) {
            if (e.error?.details?.reason === 'file_too_large') {
                this.alertsService.sendError(e, 'ERRORS.FILE.TOO-LARGE');
            } else if (e.error?.details?.reason === 'no_face') {
                this.alertsService.sendError(e, 'ERRORS.FILE.NO-FACE');
            } else {
                this.alertsService.sendError(e, 'ERRORS.UPLOAD');
            }
            this.alertsService.setAppLoading(false);

            throw (e);
        }
    }

    async confirmFace(component, fd: FormData, accountId: string) {
        try {
            HttpService.headers[ACCOUNT_HEADER] = accountId;
            const face = await this.httpService.upload<any>(component, '/core/uploads/confirm-face', fd);
            delete HttpService.headers[ACCOUNT_HEADER];
            return !!face;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async getQrLogin(component, id: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.get(component, `/core/users/${id}/qr-login`);
        } catch (e) {
            console.error(e);
        }
    }

    async generateNewQrLogin(component, id: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.put(component, `/core/users/${id}/generate-new-qr-login`, {});
        } catch (e) {
            console.error(e);
        }
    }

    async resetQrPin(component, id: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.put(component, `/core/users/${id}/reset-qr-pin`, {});
        } catch (e) {
            console.error(e);
        }
    }

    async hasAccountPermissionInAnyAccount(action: PermissionAction) {
        if (!Object.values(this.permissionCheckers).length) return false;
        return Object.values(this.permissionCheckers).some(x => x.hasAccountPermission(action));
    }
}
