import { Injectable } from '@angular/core';
import {
    CallClient,
    CallEndReason,
    DeviceManager,
    LocalVideoStream,
    TeamsCall,
    TeamsCallAgent,
    TeamsIncomingCall,
    VideoOptions,
} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential, CommunicationIdentifierKind, MicrosoftTeamsUserIdentifier } from '@azure/communication-common';
import { CommunicationAccessToken } from '@azure/communication-identity';
import { authentication, call as msCall } from '@microsoft/teams-js';
import { LocalDeviceUtility } from '@weavix/domain/utils/local-device-utility';
import { StAction, StObject } from '@weavix/models/src/analytics/analytics';
import { PermissionAction } from '@weavix/permissions/src/permissions.model';
import { AnalyticsService } from 'crews/app/core/services/analytics.service';
import { myUser, User } from 'crews/app/models-mobx/users-store/users-store';
import { ChannelDisplay } from 'crews/app/radio/channels.component';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { sleep } from 'weavix-shared/utils/sleep';
import { AlertService } from '../alert.service';
import { HttpService } from '../http.service';

import * as moment from 'moment';

const DEFAULT_WAIT_MS = 10000;

export interface CallRequest {
    channel: ChannelDisplay;
    initiator: User;
    type: CallType;
}

export enum CallType {
    Video = 'video',
    Voice = 'voice',
}

export interface CameraState {
    isDisabled: boolean;
}

export interface MuteState {
    isMuted: boolean;
}

export interface UserNotification {
    topic: string;
    data: any;
}

@Injectable({
    providedIn: 'root',
})
export class AcsService {
    constructor(
        private httpService: HttpService,
        private alertService: AlertService,
    ) { }

    private accessToken: CommunicationAccessToken = null;
    private allowVideoCalls: boolean = false;
    private allowVoiceCalls: boolean = false;
    private callAgent: TeamsCallAgent = null;
    private callClient: CallClient = null;
    private currentCall: TeamsCall = null;
    private deviceManager: DeviceManager = null;
    private localVideoStream: LocalVideoStream = null;
    private userAllowsVideo: boolean = false;
    private calledChannelId: string;

    private callType: CallType;
    private localMuteState: MuteState;
    private localCameraState: CameraState;
    private callConnectedTimestamp: number;
    private lastCallDuration: number;

    callConnected$: Subject<TeamsCall> = new Subject<TeamsCall>();
    callEnded$: Subject<CallEndReason> = new Subject<CallEndReason>();
    callIncoming$: Subject<TeamsIncomingCall> = new Subject<TeamsIncomingCall>();
    callRequested$: Subject<CallRequest> = new Subject<CallRequest>();
    callRinging$: Subject<void> = new Subject<void>();
    callStarted$: Subject<TeamsCall> = new Subject<TeamsCall>();
    cameraStateChanged$: Subject<CameraState> = new Subject<CameraState>();
    muteStateChanged$: Subject<MuteState> = new Subject<MuteState>();
    recording$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    transcribing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get currentCalledChannelId() {
        return this.calledChannelId;
    }

    setCurrentCalledChannelId(value: string) {
        this.calledChannelId = value;
    }

    async getAccessToken(): Promise<CommunicationAccessToken> {
        return this.httpService.get<CommunicationAccessToken>(null, '/acs/access-token');
    }

    async getCurrentAccessToken(): Promise<CommunicationAccessToken> {
        if (!this.accessToken || this.accessToken.expiresOn <= new Date()) {
            this.accessToken = await this.getAccessToken();
        }
        return this.accessToken;
    }

    getUserConsentUrl(userId: string, tenantId: string): Promise<string> {
        return this.httpService.get(null, `/teams/consent/user-url?userId=${userId}&tenantId=${tenantId}`);
    }

    async init(userId: string, allowVideoCalls: boolean, allowVoiceCalls: boolean, force?: boolean): Promise<void> {
        this.allowVideoCalls = allowVideoCalls;
        this.allowVoiceCalls = allowVoiceCalls;
        let acsToken: CommunicationAccessToken = null;
        if (environment.teamsApp) {
            if (!await this.httpService.get<boolean>(null, '/acs/has-ad-token')) {
                const testTokenRequest = {
                    successCallback: () => { },
                    failureCallback: (e) => {console.error('Failed to get auth token', e); },
                    url: `${window.location.origin}/teams/teams-authenticate?userId=${userId}`,
                };
                await authentication.authenticate(testTokenRequest);
            }
            return;
        }

        // if no access to teams integrations on any account, don't request a login and don't start the call agent.
        const hasAccess = myUser().accounts.some(account => {
            return account.enabledActions?.some(action => action === PermissionAction.EditTeamIntegration);
        });
        if (!hasAccess) return;

        while (!acsToken) {
            try {
                acsToken = await this.getCurrentAccessToken();
            } catch (e) {
                console.error(`Unable to get an access token: ${e}`);
            } finally {
                if (!acsToken) {
                    await sleep(DEFAULT_WAIT_MS);
                }
            }
        }

        if (!this.callClient || force) {
            this.callClient = new CallClient();
            const refreshToken = async(): Promise<string> => (await this.getAccessToken()).token;
            const token = new AzureCommunicationTokenCredential({ tokenRefresher: refreshToken, token: this.accessToken.token, refreshProactively: true });
            this.callAgent = await this.callClient.createTeamsCallAgent(token);
            this.deviceManager = await this.callClient.getDeviceManager();

            const defaultMicrophone = await LocalDeviceUtility.getDefaultAudioInputDevice();
            this.changeMicrophone(defaultMicrophone.deviceId);

            const defaultSpeaker = await LocalDeviceUtility.getDefaultAudioOutputDevice();
            this.changeSpeaker(defaultSpeaker.deviceId);

            this.callAgent.on('incomingCall', async args => this.incomingCall(args.incomingCall));
        }
    }

    ready(): boolean {
        return this.accessToken && this.callAgent ? true : false;
    }

    isOnACall(): boolean { return !!this.currentCall; }

    // returns list of email addresses of other people in the channel that can be called using Team
    // meeting functionality.
    async getChannelRecipientsEmails(channelId: string) {
        return this.httpService.get<any>(null, `/core/channels/${channelId}/call-recipients-emails`);
    }

    // returns a list of Teams identity for other people in the channel.
    async getChannelRecipientsIds(channelId: string) {
        return this.httpService.get<any>(null, `/core/channels/${channelId}/call-recipients`);
    }

    async getUserIdentity(userId: string) {
        return this.httpService.get<any>(null, `/acs/identity/${userId}`);
    }

    async requestCall(channel: ChannelDisplay, type: CallType, initiator: User) {
        this.callRequested$.next({ channel, type, initiator });
    }

    async createCallSystemMessage(channelId: string) {
        return await this.httpService.get(null, `/core/channels/${channelId}/called-channel`);
    }

    // Starts a video/voice call with all participants of a channel. This call utilizes
    // Teams library to start the call instead of ACS.
    async startAndJoinCall(channelId: string, callType: CallType) {
        try {
            if (!environment.teamsApp) await this.askPermissions(this.allowVideoCalls);
            const response = await this.getChannelRecipientsEmails(channelId);
            if (!response.length) this.alertService.sendError(null, 'ERRORS.CALLS.USER_UNAVAILABLE');
            else {
                this.callType = callType;
                const modalities = callType === CallType.Video ? [msCall.CallModalities.Video] : [msCall.CallModalities.Audio];
                const targets = [response];

                AnalyticsService.track(
                    this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
                    StAction.Started,
                    this.constructor.name,
                    {
                        object: {
                            channelId,
                            channelMemberCount: targets?.length + 1,
                        },
                    },
                );

                await msCall.startCall({ targets, requestedModalities: modalities });
            }
        } catch (e) {
            if (!e) console.error('Failed to start (and join) a call.', e);
            else this.createCallSystemMessage(channelId);
        }
    }

    // Starts a video/voice call with one user. This call utilizes Teams library to start the call instead of ACS.
    async startOneToOneCall(userId: string, callType: CallType) {
        if (!environment.teamsApp) await this.askPermissions(this.allowVideoCalls);
        const response = await this.getUserIdentity(userId);
        if (!response.length) this.alertService.sendError(null, 'ERRORS.CALLS.USER_UNAVAILABLE');
        else {
            this.callType = callType;
            const modalities = callType === CallType.Video ? [msCall.CallModalities.Video] : [msCall.CallModalities.Audio];
            const targets = [response];

            AnalyticsService.track(
                this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
                StAction.Started,
                this.constructor.name,
                {
                    object: {
                        channelMemberCount: targets?.length + 1,
                    },
                },
            );

            msCall.startCall({ targets, requestedModalities: modalities });
        }
    }

    // startDirectCall is used to start a call between this user and recipient via ACS.
    //
    // In web radio, we are using Teams call agent to make calls and as of v1.13.1 of
    // Azure communication calling library, it doesn't support more than one participant in
    // starting a call.
    async startDirectCall(channelId: string, type: CallType): Promise<TeamsCall> {
        await this.askPermissions(this.allowVideoCalls);
        this.callType = type;
        const recipientIds = await this.getChannelRecipientsIds(channelId);
        // remove the '8:orgid:' appended to id retrieved from backend to set microsoftTeamUserId
        // Once @azure/communication-common has been updated to a newer version, we can use their createIdentifierFromRawId method instead,
        const ids: MicrosoftTeamsUserIdentifier[] = recipientIds.map(id => ({ microsoftTeamsUserId: id.substring(0, 8) === '8:orgid' ? id.substring(8) : id, rawId: id }));
        if (type === CallType.Video && this.userAllowsVideo && !this.localVideoStream) this.localVideoStream = await this.createLocalVideoStream();
        const videoOptions = this.localVideoStream && type === CallType.Video ? { localVideoStreams: [this.localVideoStream] } : undefined;
        try {
            this.currentCall = this.callAgent.startCall(ids[0], { videoOptions });
            this.setCurrentCalledChannelId(channelId);
            this.subscribeToEvents(this.currentCall);
            if (type === CallType.Video && this.allowVideoCalls) this.cameraStateChanged$.next({ isDisabled: true });
            this.callStarted$.next(this.currentCall);

            AnalyticsService.track(
                this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
                StAction.Started,
                this.constructor.name,
                {
                    object: {
                        channelId,
                        callId: this.currentCall.id,
                        channelMemberCount: recipientIds?.length + 1,
                    },
                },
            );
            return this.currentCall;
        } catch (e) {
            console.error('Failed to start the call.', e);
            return null;
        }
    }

    async endCall(forEveryone: boolean = false): Promise<void> {
        if (this.currentCall) {
            this.currentCall.hangUp( { forEveryone: forEveryone });
        } else {
            this.callEnded$.next({ code: 0, subCode: 0 });
        }

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Ended,
            this.constructor.name,
            {
                object: {
                    callId: this.currentCall?.id,
                    callDuration: this.lastCallDuration,
                    video: this.localCameraState?.isDisabled === false ? 'on' : 'off',
                    audio: this.localMuteState?.isMuted === false ? 'on' : 'off',
                },
            },
        );
    }

    async createLocalVideoStream(): Promise<LocalVideoStream> {
        const selectedCamera = await LocalDeviceUtility.getDefaultVideoDevice();
        const cameras = await this.deviceManager.getCameras();
        let camera = cameras.find(x => x.id.replace('camera:', '') === selectedCamera.deviceId);
        if (!camera) {
            camera = cameras[0];
            LocalDeviceUtility.setDefaultVideoInput(camera.id.replace('camera:', ''));
        }
        if (camera) {
            return new LocalVideoStream(camera);
        }
        throw new Error('camera not available');
    }

    private async processStateChange(): Promise<void> {
        console.log(`Current Call ${this.currentCall.id} state changed: ${this.currentCall.state}`);
        switch (this.currentCall.state) {
            case 'Connected':
                this.callConnectedTimestamp = moment.now();
                this.callConnected$.next(this.currentCall);
                break;
            case 'Disconnected':
                console.log(`Current call ended with code: ${this.currentCall.callEndReason.code} and sub-code: ${this.currentCall.callEndReason.subCode}`);
                this.lastCallDuration = moment.now() - this.callConnectedTimestamp;
                this.callEnded$.next(this.currentCall.callEndReason);
                this.cleanUpCall();
                break;
            case 'Ringing':
                this.callRinging$.next();
        }
    }

    async refreshToken(): Promise<string> {
        return (await this.getAccessToken()).token;
    }

    private cleanUpCall(): void {
        this.unsubscribeToEvents(this.currentCall);
        this.currentCall = null;
        this.callConnectedTimestamp = 0;
        this.localCameraState = null;
        this.localMuteState = null;
        this.callType = null;
        this.localVideoStream = null;
    }

    private async incomingCall(call: TeamsIncomingCall): Promise<void> {
        console.log(`Incoming call: id ${call.id} from "${call.callerInfo.displayName}`);
        if (this.currentCall) {
            await call.reject();
            return;
        }
        this.callIncoming$.next(call);
    }

    async acceptCall(call: TeamsIncomingCall, type: CallType): Promise<void> {
        if (!this.allowVideoCalls && !this.allowVoiceCalls) {
            console.error('User not allowed to receive either type of calls. Rejecting this call.');
            return await this.rejectCall(call);
        }

        await this.askPermissions(this.allowVideoCalls);
        this.callType = type;
        const videoOptions: VideoOptions = {};
        if (this.allowVideoCalls && this.userAllowsVideo && type === CallType.Video) {
            if (!this.localVideoStream) {
                this.localVideoStream = await this.createLocalVideoStream();
            }
            videoOptions.localVideoStreams = [this.localVideoStream];
        }
        this.currentCall = await call.accept({ videoOptions: videoOptions });
        this.subscribeToEvents(this.currentCall);
        this.callStarted$.next(this.currentCall);

        AnalyticsService.track(
            this.callType === CallType.Video ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Joined,
            this.constructor.name,
            {
                object: {
                    callId: this.currentCall?.id,
                },
            },
        );
    }

    async rejectCall(call: TeamsIncomingCall): Promise<void> {
        await call.reject();
        this.callEnded$.next();
    }

    async muteMicrophone(): Promise<boolean> {
        if (!this.currentCall || this.currentCall.isMuted) return false;
        await this.currentCall.mute();
        if (this.currentCall.isMuted) this.muteStateChanged$.next({ isMuted: true });
        AnalyticsService.track(StObject.CallAudio, StAction.Toggled, this.constructor.name, { action: 'off' });
        return this.currentCall.isMuted;
    }

    async unmuteMicrophone(): Promise<boolean> {
        if (!this.currentCall || !this.currentCall.isMuted) return false;
        await this.currentCall.unmute();
        if (!this.currentCall.isMuted) this.muteStateChanged$.next({ isMuted: false });
        AnalyticsService.track(StObject.CallAudio, StAction.Toggled, this.constructor.name, { action: 'on' });
        return !this.currentCall.isMuted;
    }

    async turnOffCamera(): Promise<boolean> {
        if (!this.currentCall && !this.localVideoStream) return false;
        await this.currentCall.stopVideo(this.localVideoStream);
        this.localVideoStream = null;
        this.cameraStateChanged$.next({ isDisabled: true });
        AnalyticsService.track(StObject.CallCamera, StAction.Toggled, this.constructor.name, { action: 'off' });
        return true;
    }

    async turnOnCamera(): Promise<boolean> {
        if (!this.currentCall && this.localVideoStream) return false;
        this.localVideoStream = await this.createLocalVideoStream();
        await this.currentCall.startVideo(this.localVideoStream);
        this.cameraStateChanged$.next({ isDisabled: false });
        AnalyticsService.track(StObject.CallCamera, StAction.Toggled, this.constructor.name, { action: 'on' });
        return true;
    }

    async changeCamera(deviceId: string): Promise<boolean> {
        if (!this.currentCall || !this.localVideoStream) return false;
        const cameras = await this.deviceManager.getCameras();
        const camera = cameras.find(x => x.id.replace('camera:', '') === deviceId);
        if (camera) this.localVideoStream.switchSource(camera);
        return true;
    }

    async changeMicrophone(deviceId: string): Promise<boolean> {
        if (!this.deviceManager) return false;
        const microphones = await this.deviceManager.getMicrophones();
        const microphone = microphones.find(x => x.id.replace('microphone:', '') === deviceId);
        if (microphone) this.deviceManager.selectMicrophone(microphone);
        return true;
    }

    async changeSpeaker(deviceId: string): Promise<boolean> {
        if (!this.deviceManager) return false;
        const speakers = await this.deviceManager.getSpeakers();
        const speaker = speakers.find(x => x.id.replace('speaker:', '') === deviceId);
        if (speaker) this.deviceManager.selectSpeaker(speaker);
        return true;
    }

    async askPermissions(wantVideo: boolean): Promise<void> {
        if (!this.deviceManager) this.deviceManager = await this.callClient.getDeviceManager();
        this.userAllowsVideo = false;
        if (wantVideo) {
            const callResponse = await this.deviceManager.askDevicePermission({ audio: false, video: true });
            this.userAllowsVideo = callResponse.video;
        }
        await this.deviceManager.askDevicePermission({ audio: true, video: false });
    }

    async processMuteStateChange(): Promise<void> {
        console.log(`Call ${this.currentCall.id} is now ${this.currentCall.isMuted ? '' : 'un'}muted.`);
        this.muteStateChanged$.next({ isMuted: this.currentCall.isMuted });
    }

    private subscribeToEvents(call: TeamsCall): void {
        call.on('isMutedChanged', () => this.processMuteStateChange());
        call.on('stateChanged', () => this.processStateChange());
    }

    private unsubscribeToEvents(call: TeamsCall): void {
        call.off('isMutedChanged', () => this.processMuteStateChange());
        call.off('stateChanged', () => this.processStateChange());
    }

    get hasLocalVideoStream(): boolean {
        return !!this.localVideoStream;
    }

    get isMuted(): boolean {
        return this.currentCall ? this.currentCall.isMuted : false;
    }

    getIdFromAcsIdentifier(identifier: CommunicationIdentifierKind): string {
        switch (identifier.kind) {
            case 'communicationUser':
                return identifier.communicationUserId;

            case 'microsoftTeamsUser':
                return identifier.microsoftTeamsUserId;

            case 'phoneNumber':
                return identifier.phoneNumber;

            case 'unknown':
                return identifier.id;

            default:
                return null;
        }
    }

    getRawIdFromAcsIdentifier(identifier: CommunicationIdentifierKind): string {
        switch (identifier.kind) {
            case 'communicationUser':
                return identifier.communicationUserId;

            case 'microsoftTeamsUser':
                return identifier.rawId;

            case 'phoneNumber':
                return identifier.rawId;

            case 'unknown':
                return identifier.id;

            default:
                return null;
        }
    }

    logCall(call: TeamsCall): void {
        console.log(`${call.direction} ${call.kind} with ID ${call.id} details:`);
        console.log(`This call is currently ${call.isMuted ? 'muted' : 'unmuted'} and in ${call.state} state.`);
        // eslint-disable-next-line max-len
        console.log(`Local participant has ${call.localAudioStreams.length} audio streams (${call.localAudioStreams.map(las => las.mediaStreamType).join()}) and ${call.localVideoStreams.length} video streams (${call.localVideoStreams.map(lvs => lvs.mediaStreamType).join()}).`);
        console.log(`Number of remote participants: ${call.remoteParticipants.length}`);
        call.remoteParticipants.forEach(rp => {
            const id = this.getRawIdFromAcsIdentifier(rp.identifier);
            console.log(`Remote participant ${id} (${rp.displayName}) has ${rp.videoStreams.length} video streams (${rp.videoStreams.map(x => x.mediaStreamType).join()}), and is ${rp.isMuted ? '' : 'not '}muted.`);
            const vs = rp.videoStreams.find(s => s.mediaStreamType === 'Video');
            if (vs) {
                console.log(`Remote participant ${id} 'Video' stream has a size of ${vs.size.width} by ${vs.size.height} and is ${vs.isAvailable ? '' : 'not '}available.`);
            } else {
                console.log(`Remote participant ${id} has no 'Video' streams.`);
            }
        });
    }

    async createMissedCallNotification(callId: string, callerId: string) {
        try {
            await this.httpService.put(null, '/acs/missed-call-notification', { callId, callerId });
        } catch (e) {
            console.error('failed to create missed call notification', e);
        }
    }
}
