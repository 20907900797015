import { merge } from 'lodash';
import { Subject } from 'rxjs';
const AUDIO_INPUT_DEVICE_KEY = 'audio-input-device';
const AUDIO_OUTPUT_DEVICE_KEY = 'audio-output-device';
const VIDEO_INPUT_DEVICE_KEY = 'video-input-device';

export class LocalDeviceUtility {
    static denied$ = new Subject();

    static setDefaultAudioInput(id: string) {
        localStorage.setItem(AUDIO_INPUT_DEVICE_KEY, id);
    }
    static setDefaultAudioOutput(id: string) {
        localStorage.setItem(AUDIO_OUTPUT_DEVICE_KEY, id);
    }
    static setDefaultVideoInput(id: string) {
        localStorage.setItem(VIDEO_INPUT_DEVICE_KEY, id);
    }

    static async checkPermissions(audio: boolean, video?: boolean) {
        if ((!audio || (await this.getAudioInputDevices())[0]?.deviceId) && (!video || (await this.getVideoDevices())[0]?.deviceId)) return true;
        const { audioTrack, videoTrack } = await LocalDeviceUtility.getAudioVideoTracks(video, audio);
        if (!audioTrack && !videoTrack) return false;
        audioTrack?.stop();
        videoTrack?.stop();
        return true;
    }

    static async getAudioVideoTracks(videoDeviceId?: string | boolean, audioDeviceId?: string | boolean, useDummyIfEmpty?: boolean) {
        const stream = await this.getDeviceStream(audioDeviceId, videoDeviceId);

        const videoTrack = stream?.getVideoTracks()?.[0] ?? (useDummyIfEmpty ? this.getDummyVideoTrack() : null);
        const audioTrack = stream?.getAudioTracks()?.[0] ?? (useDummyIfEmpty ? this.getDummyAudioTrack() : null);

        return { videoTrack, audioTrack };
    }

    static getDummyAudioTrack() {
        const ctx = new AudioContext(), oscillator = ctx.createOscillator();
        const dest = ctx.createMediaStreamDestination();
        oscillator.connect(dest);
        oscillator.start();
        const track = dest.stream.getAudioTracks()[0];
        track.enabled = false;
        return track;
    }

    static getDummyVideoTrack(width = 1280, height = 720) {
        const canvas = Object.assign(document.createElement('canvas'), { width, height });
        canvas.getContext('2d')?.fillRect(0, 0, width, height);
        const stream: MediaStream = canvas['captureStream']();
        const track = stream.getVideoTracks()[0];
        track.enabled = false;
        return track;
    }

    static async getDeviceVideoTrack(videoDeviceId?: string | boolean) {
        if (videoDeviceId == null) return null;
        const stream = await this.getDeviceStream(null, videoDeviceId);
        return stream?.getVideoTracks()?.[0];
    }

    static async getDeviceAudioTrack(audioDeviceId: string | boolean) {
        if (audioDeviceId == null) return null;
        const stream = await this.getDeviceStream(audioDeviceId);
        return stream?.getAudioTracks()?.[0];
    }

    static async getDeviceStream(audioDeviceId?: string | boolean | null, videoDeviceId?: string | boolean | null, extra?: Partial<MediaStreamConstraints>) {
        if (audioDeviceId == null && videoDeviceId == null) return null;
        try {
            const constraints: MediaStreamConstraints = {};
            if (audioDeviceId) {
                constraints.audio = typeof audioDeviceId === 'boolean' ? audioDeviceId : { deviceId: audioDeviceId };
            }
            if (videoDeviceId) {
                constraints.video = {
                    width: 1280,
                    height: 720,
                    frameRate: 30,
                };
                if (typeof videoDeviceId !== 'boolean') constraints.video.deviceId = videoDeviceId;
            }
            if (extra) merge(constraints, extra);
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            return stream;
        } catch (e: any) {
            if (typeof e === 'object' && e) {
                if ('message' in e && e.message === 'Permission denied' // Chrome
                    || 'name' in e && e.name === 'NotAllowedError' // FF && Safari
                    || 'message' in e && typeof e.message === 'string' && e.message.includes('not allowed')
                ) {
                    this.denied$.next(null);
                    return null;
                }
            }
            console.error('Unexpected error was thrown while getting device stream', e);
            return null;
        }
    }

    static async getDefaultAudioInputDevice() {
        const audioInputDevices = await this.getAllAudioInputDevices();
        const id = localStorage.getItem(AUDIO_INPUT_DEVICE_KEY);
        return audioInputDevices.find(x => x.deviceId === id) ?? this.getDefaultAudioDevice(audioInputDevices);
    }

    static async getDefaultAudioOutputDevice() {
        const audioOutputDevices = await this.getAllAudioOutputDevices();
        const id = localStorage.getItem(AUDIO_OUTPUT_DEVICE_KEY);
        return audioOutputDevices.find(x => x.deviceId === id) ?? this.getDefaultAudioDevice(audioOutputDevices);
    }

    private static getDefaultAudioDevice(audioDevices: MediaDeviceInfo[]) {
        let audioDevice = audioDevices.filter(x => x.deviceId === 'communications')?.[0];
        if (audioDevice == null)
            audioDevice = audioDevices.filter(x => x.deviceId === 'default')?.[0];
        if (audioDevice == null)
            audioDevice = audioDevices?.[0];

        // Get real device ID
        if (['communications', 'default'].includes(audioDevice?.deviceId)) {
            audioDevice = audioDevices.find(x => !['communications', 'default'].includes(x.deviceId) && x.groupId === audioDevice.groupId) ?? audioDevice;
        }

        return audioDevice;
    }

    /**
     * Gets the default video device.
     * Tries to match to default audio device, otherwise defaults to first.
     */
    static async getDefaultVideoDevice() {
        let videoDevice: MediaDeviceInfo | undefined;

        const videoDevices = await this.getVideoDevices();
        const id = localStorage.getItem(VIDEO_INPUT_DEVICE_KEY);
        const stored = videoDevices.find(x => x.deviceId === id);
        if (stored) return stored;

        const defaultAudioDevice = await this.getDefaultAudioInputDevice();
        if (defaultAudioDevice != null) {
            videoDevice = videoDevices.find(x => x.groupId === defaultAudioDevice.groupId);
        }

        if (!videoDevice) {
            videoDevice = videoDevices?.[0];
        }

        return videoDevice;
    }

    static async getVideoDevices() {
        return await this.getDevicesByKind('videoinput');
    }

    static async getVideoDeviceById(id: string) {
        return (await this.getVideoDevices()).find(x => x.deviceId === id);
    }

    static async getAudioInputDevices() {
        return (await this.getAllAudioInputDevices()).filter(x => !['communications', 'default'].includes(x.deviceId));
    }

    private static async getAllAudioInputDevices() {
        return await this.getDevicesByKind('audioinput');
    }

    static async getAudioInputDeviceById(id: string) {
        return (await this.getAudioInputDevices()).find(x => x.deviceId === id);
    }

    static async getAudioOutputDevices() {
        const devices: Pick<MediaDeviceInfo, 'label' | 'deviceId'>[] = (await this.getAllAudioOutputDevices()).filter(x => !['communications', 'default'].includes(x.deviceId));
        if (!devices.length) devices.push({ label: 'Default', deviceId: '' });
        return devices;
    }

    private static async getAllAudioOutputDevices() {
        return await this.getDevicesByKind('audiooutput');
    }

    static async getAudioOutputDeviceById(id: string) {
        return (await this.getAudioOutputDevices()).find(x => x.deviceId === id);
    }

    static async getDevicesByKind(kind: 'videoinput' | 'audioinput' | 'audiooutput') {
        return (await navigator.mediaDevices.enumerateDevices()).filter(x => x.kind === kind);
    }

    static async getScreenStream(): Promise<MediaStream> {
        return await navigator.mediaDevices['getDisplayMedia']({ video: true });
    }


}
