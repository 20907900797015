/**
 * Copied from variables.scss
 */
export const css = {
    // FUNCTIONAL COLORS
    colors: {
        WHITE: '#ffffff',
        WHITE_30P: 'rgba(255, 255, 255, .3)',
        WHITE_65P: 'rgba(255, 255, 255, .65)',

        RED: '#BD1F2E',
        DK_RED: '#8B0000',

        PINK: '#f07da8',

        GREEN: '#49CA8E',
        DK_GREEN: '#228B22',
        ORANGE: '#FFA500',

        GRAY: '#7B868C',
        DK_GRAY: '#373A36',
        MED_GRAY: '#EBEFF7',
        LT_GRAY: '#F9FBFF',
        GRAY_50P: 'rgba(48,56,75, .5)',
        GRAY_5P: 'rgba(48,56,75, .05)',

        BLUE: '#345CF6',
        DK_BLUE: '#005cb9',
        LT_BLUE: '#4197cb',
        LT_BLUE_25P: 'rgba(65, 151, 203, 0.25)',

        BLACK_38P: 'rgba(0, 0, 0, 0.38)',

        BLUE_GRAY: '#b2cae4',

        ALMOST_BLACK: '#181818',
        BLACK: '#000000',

        INFO_BLUE: '#1098D0',
        WARNING_YELLOW: '#FFAB00',
        DANGER_RED: '#FB1549',

        // Crews Grays
        BG_GRAY: '#181818',
        GRAY_DK: '#262626',
        GRAY_MD: '#3c3c3c',
        GRAY_LT: '#686868',

        AVATAR_ICON: '#888888',
        AVATAR_BACKGROUND: '#EEEEEE',
    },

    // BRANDING COLOR PALETTE COLORS  from 1_14_20
    brandingColors: {
        W_DK_BLUE: '#0063a7',
        W_LT_BLUE: '#4197cb',
        W_GOLD: '#f5c851',
        W_ORANGE: '#ef823f',
        W_GREEN: '#5bbc93',
        W_LAVENDER: '#c6d8ff',
        W_PURPLE: '#441151',
        W_PINK: '#f07da8',
        W_RED: '#ff1b1c',
        W_TEAL: '#7cffcb',
        W_DK_GRAY: '#373a36',
        W_LT_GRAY: '#7b868c',
        W_BG_DARK: '#231F20',
        W_BG_LT: '#d3d0cb',
        W_LT_TEAL: '#bffff1',
    },
};

export const CSS_BREAKPOINTS = {
    mobile: 420,
    tablet: 768,
    smallscreen: 1024,
    laptop: 1366,
    full: 1720,
};
