import { UserSetting, UserSettingName, UserSettingValue } from '@weavix/models/src/user/UserSettings';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { UserSettingsServiceStub } from '@weavix/services/user-settings.service';

@Injectable()
export class UserSettingsService extends UserSettingsServiceStub {

    constructor(
        private httpService: HttpService,
    ) {
        super();
    }

    getAllUserSettings(component: any) {
        return this.httpService.get<UserSetting[]>(component, '/user/settings');
    }

    getUserSetting(component: any, settingName: UserSettingName) {
        return this.httpService.get<UserSetting>(component, `/user/settings/${settingName}`);
    }

    upsertUserSetting(component: any, settingName: UserSettingName, setting: { settings: UserSettingValue } ) {
        return this.httpService.put<UserSettingValue>(component, `/user/settings/${settingName}`, setting);
    }
}
