// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../node_modules/@types/segment-analytics/index.d.ts" />

import { StAction, StObject, StProperties } from '@weavix/models/src/analytics/analytics';
import { AnalyticsServiceStub } from '@weavix/services/src/analytics.service';
import { environment } from 'environments/environment';

export class AnalyticsService extends AnalyticsServiceStub {
    private static readonly source = environment.analyticsSource;
    private static readonly version = environment.version;

    static track(object: StObject, action: StAction, component: string, data: StProperties = {}): void {
        super.track(object, action, component, data, AnalyticsService.source, AnalyticsService.version);
    }

    static page(page: string) {
        super.page(page, AnalyticsService.source, AnalyticsService.version);
    }
}
