/// <reference path="../node_modules/@types/segment-analytics/index.d.ts" />

import { IdentifyTraits, StProperties, allowedKeys } from '@weavix/models/src/analytics/analytics';
import { Utils } from '@weavix/utils/src/utils';

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
    }
}

export abstract class AnalyticsServiceStub {
    private static readonly USE_ANALYTICS_KEY = 'use-analytics';

    static trackIt() {
        return document.cookie.indexOf('e2e=true') === -1
         && !!localStorage.getItem(AnalyticsServiceStub.USE_ANALYTICS_KEY);
    }

    static start() {
        localStorage.setItem(this.USE_ANALYTICS_KEY, 'true');
    }

    static stop() {
        localStorage.removeItem(this.USE_ANALYTICS_KEY);
    }

    static identify(userId: string, traits: IdentifyTraits): void {
        if (this.trackIt()) window.analytics.identify(userId, { ...traits });
    }

    static track(object: string, action: string, component: string, data: StProperties = {}, source: string, version: string): void {
        try {
            if (this.trackIt()) {
                const allProps: StProperties = { object, action, source, version, component, ...Utils.redact(data, allowedKeys) };
                window.analytics.track(`${object} : ${action}`, allProps);
            }
        } catch (e) {
            console.error(e);
        }
    }

    static error(method: string, endpoint: string, component: string, errorData: StProperties = {}, source: string, version: string): void {
        try {
            if (this.trackIt()) {
                const allProps: StProperties = { method, endpoint, source, version, component, ...Utils.redact(errorData, allowedKeys) };
                window.analytics.track(`HTTP ERROR : ${method}`, allProps);
            }
        } catch (e) {
            console.error(e);
        }
    }

    static page(page: string, source: string, version: string): void {
        try {
            if (this.trackIt()) {
                const allProps: StProperties = { action: 'Viewed', page, source, version };
                window.analytics.track(`${page} : Viewed`, allProps);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
