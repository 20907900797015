import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { SharedCheckboxComponent } from './form-inputs/checkbox/checkbox.component';
import { SharedDatePickerComponent } from './form-inputs/date-picker/date-picker.component';
import { SharedTimePickerComponent } from './form-inputs/time-picker/time-picker.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [
        SharedCheckboxComponent,
        SharedDatePickerComponent,
        SharedTimePickerComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
    ],
    exports: [
        SharedCheckboxComponent,
        SharedDatePickerComponent,
        SharedTimePickerComponent,
    ]
})
export class WeavixFormInputsModule { }
