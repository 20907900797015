import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AvatarEditorComponent } from './avatar/avatar-editor/avatar-editor.component';
import { AvatarPersonClusterComponent } from './avatar/avatar-person-cluster/avatar-person-cluster.component';
import { AvatarPersonComponent } from './avatar/avatar-person/avatar-person.component';
import { AvatarSingleClusterComponent } from './avatar/avatar-single-cluster/avatar-single-cluster.component';
import { AvatarComponent } from './avatar/avatar.component';
import { CalloutComponent } from './callout/callout.component';
import { IconComponent } from './icon/icon.component';
import { LoaderComponent } from './loader/loader.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoSpinnerComponent } from './logo-spinner/logo-spinner.component';
import { ModalComponent } from './modal/modal.component';
import { HtmlEncode } from './pipes/html-encode.pipe';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { MaybeDecimalPipe } from './pipes/maybe-decimal.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { PttModalComponent } from './ptt-modal/ptt-modal.component';
import { TableRowComponent } from './table/table-row/table-row.component';
import { TableComponent } from './table/table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [
        AvatarComponent,
        AvatarEditorComponent,
        AvatarPersonComponent,
        AvatarPersonClusterComponent,
        AvatarSingleClusterComponent,
        CalloutComponent,
        IconComponent,
        LoaderComponent,
        LoadingComponent,
        LogoSpinnerComponent,
        TableComponent,
        TableRowComponent,
        HtmlEncode,
        LinkifyPipe,
        MaybeDecimalPipe,
        ModalComponent,
        PttModalComponent,
        SafeHtmlPipe,
        ShortNumberPipe,
    ],
    imports: [
        CommonModule,
        ScrollingModule,
        FormsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatIconModule,
        MatSliderModule,
        MatTooltipModule,
        NgxSkeletonLoaderModule,
    ],
    exports: [
        AvatarComponent,
        AvatarEditorComponent,
        AvatarPersonComponent,
        AvatarPersonClusterComponent,
        AvatarSingleClusterComponent,
        CalloutComponent,
        IconComponent,
        LoaderComponent,
        LoadingComponent,
        LogoSpinnerComponent,
        TableComponent,
        TableRowComponent,
        HtmlEncode,
        LinkifyPipe,
        MaybeDecimalPipe,
        ModalComponent,
        PttModalComponent,
        SafeHtmlPipe,
        ShortNumberPipe,
    ]
})
export class WeavixComponentsModule { }
