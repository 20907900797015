import { LinkPreview, SystemMessageType } from '@weavix/models/src/channel/channel-message';
import { ImageUpload } from './upload.model';

export enum ChannelAlertsType {
    alert = 'alert',
    sms = 'sms',
    badge = 'badge',
    mobileAlert = 'mobileAlert',
    facilityOnly = 'facilityOnly',
}

export interface ChannelTyper {
    personId: string;
    status: ChannelTyperStatus;
    expires: Date;
}

export interface ChannelTypers {
    typers: ChannelTyper[];
}

export enum ChannelTyperStatus {
    Talking = 'talking',
    Typing = 'typing',
    None = 'none',
}

export interface ChannelAlerts {
    [ChannelAlertsType.alert]: boolean;
    [ChannelAlertsType.sms]: boolean;
    [ChannelAlertsType.badge]: boolean;
    [ChannelAlertsType.mobileAlert]: boolean;
    [ChannelAlertsType.facilityOnly]: boolean;
}

export interface ChannelReadResponse {
    channelId?: string;
    readSequence?: number;
}

export enum ChannelType {
    Channel = 'channel',
    People = 'people',
}

export interface Channel {
    id?: string;
    accountId?: string;
    facilityId?: string;
    facilityName?: string;
    people?: string[];
    peopleTags?: string[];
    peopleCrafts?: string[];
    includeFacilityPeople?: boolean;
    type?: ChannelType;
    name?: string;
    defaultNotification?: ChannelAlerts;
    preferencesDisabled?: boolean;
    notification?: ChannelAlerts;
    sequence?: number;
    readSequences?: { [id: string]: number; };
    readSequence?: number;
    tags?: string[];
    lastMessages?: ChannelMessage[];
    updated?: string;
    isSnoozed?: boolean;
    deleted?: boolean;
}

export enum MessageType {
    Text = 'text',
    Form = 'form',
    Video = 'video',
    Audio = 'audio',
    Page = 'page',
}

export enum ChannelSource {
    Mobile = 'mobile',
    Walt = 'walt',
    Web = 'web',
}

export interface ChannelMessage {
    channelId: string;
    date: string;
    id: string;
    message: string;
    sender?: {
        id?: string;
        firstName?: string;
        lastName?: string;
        avatarFile?: string;
    };
    notification?: ChannelAlerts;
    type: MessageType;
    data?: ChannelMessageData;
}

export interface ChannelMessageData {
    ruleId?: string;
    event?: any;
    form?: { id?: string; version?: string; name?: string; title?: string };
    file?: { id?: string; name?: string };
    buttons?: { acceptButtonText?: string, cancelButtonText?: string };
    attachments?: MessageAttachment[];
    links?: string[];
    pager?: { id?: string; firstName?: string; lastName?: string; message?: string; };
    radio?: string; // <serial> - <radio>
    linkPreviews?: URLLinkPreview[] | LinkPreview[];
    systemMessageType?: SystemMessageType;
    onBehalfOf?: { userId: string, fullName: string }; // for auto-replies, shows who the auto reply came from
    locale?: string;
}

export interface URLLinkPreview extends LinkPreview {
    isLoading: boolean;
}

export interface LinkPreviewRequest {
    url: string;
}

export interface DirectMessage {
    message: string;
    data?: ChannelMessageData;
    source?: ChannelSource;
    refId?: string;
    fromTeams?: boolean;
}

export interface Communication {
    id: string;
    date: string;
    facilityId: string;
    personId: string;
    location?: number[];
    type: MessageType;
    duration: number;
    onsite?: boolean;
    source?: ChannelSource;
}

export interface ChannelMessageQuery {
    toId?: string;
    toDate?: string;
    limit?: number;
    contentType?: MessageAttachmentType[];
}

export const ALLOWED_IMAGE_FIlE_ATTACHMENT_TYPES: string[] = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.webp',
    '.bpm',
    '.dip',
    '.ico',
    '.jfif',
];
export const ALLOWED_VIDEO_FILE_ATTACHMENT_TYPES: string[] = [
    '.mp4',
    '.webm',
    '.ogg',
    '.mov',
];
export const NOT_ALLOWED_FILE_TYPES: string[] = [
    '.exe',
];

export enum MessageAttachmentType {
    Image = 'image',
    Video = 'video',
    File = 'file',
    Audio = 'audio',
}
export interface MessageAttachment {
    type: MessageAttachmentType;
    file: ImageUpload;
    thumbnailUri?: string;
    loading?: boolean;
    locale?: string;
}

export enum AddCarouselActionType {
    APPEND = 'append',
    PREAPPEND = 'preappend',
}
