import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { EmailValidationResult, ShortCodeData } from '../models/auth.model';

import { PermissionAction } from '@weavix/permissions/src/permissions.model';
import { AccountServiceStub } from '@weavix/services/src/account.service';
import { AnalyticsService } from 'crews/app/core/services/analytics.service';
import { companiesContext } from 'crews/app/models-mobx/companies-store/companies-store';
import { environment } from 'environments/environment';
import { LoginType } from 'weavix-shared/models/login.model';
import { Account, Feature } from '../models/account.model';
import { AccountFeaturesUpdated } from '../models/event.model';
import { Person, PersonUpdate, SelfRegisterNewUserPersonRequest } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { LoggedInUser } from '../models/user.model';
import { Utils } from '../utils/utils';
import { AcsService } from './acs/acs.service';
import { HttpService } from './http.service';
import { ProfileService } from './profile.service';
import { PubSubService } from './pub-sub.service';
import { PushService } from './push.service';

@Injectable()
export class AccountService extends AccountServiceStub {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private profileService: ProfileService,
        private cookieService: CookieService,
        private pushService: PushService,
        private http: HttpClient,
        private acsService: AcsService,
    ) {
        super();
    }

    private static readonly ACCOUNT_ID_COOKIE_KEY = 'accountId';
    private features: Feature[] = [];
    private user: Promise<LoggedInUser>;
    private currentAccountId: string;
    private currentAccountRoute: string;
    private accountFeaturesSubscription: Subscription;

    features$: BehaviorSubject<Feature[]> = new BehaviorSubject<Feature[]>([]);
    account$: ReplaySubject<Account> = new ReplaySubject<Account>(1);
    canaryAvailable: boolean;

    async connect(force: boolean = false) {
        if (this.user && !force) return;

        try {
            this.user = this.getUser(null).catch(e => null);
            await this.setAccount(await this.getInitialAccount());

            // Intentionally not blocking, waiting for user response
            const currentUser = await this.user;

            const wantAudio = this.profileService.hasPermissionInAnyFacility(PermissionAction.VoiceCalling);
            const wantVideo = this.profileService.hasPermissionInAnyFacility(PermissionAction.VideoCalling);
            try {
                this.acsService.init(currentUser.id, wantVideo, wantAudio);
            } catch (e) {
                console.error(`Failed to initialize AcsService: ${e}`);
            }
            if (currentUser) this.pushService.register(currentUser.id, this.getAccountId());

            return currentUser;
        } catch (e) {
            console.error(e);
        }
    }

    private async setAccount(account: Account) {
        if (!account) return;
        this.currentAccountId = account.id;
        this.currentAccountRoute = Utils.getAccountUrl(account.name);

        this.setAccountCookie(account.id);

        await this.profileService.getUserProfile(null, true); // sets the user profile

        const user = await this.user;
        AnalyticsService.identify(user?.id, {
            email: (user?.email ?? user?.phone ?? 'NONE'),
            name: `${user?.firstName} ${user?.lastName}`,
            accountId: account.id,
        });

        this.features = account.features;
        this.subscribeToAccountFeatures(account.id);
        this.account$.next(account);
    }

    async hasFeature(key: string, value: number) {
        const feat = this.features.find(f => f.key === key);
        return feat && feat.value >= value;
    }

    async getAccount(): Promise<Account> {
        const accounts = (await this.user).accounts;
        const account = await this.getAccountBasedOnCookie(accounts);
        return { ...account };
    }

    getAccountId() {
        return this.currentAccountId;
    }

    getAccountRoute() {
        return this.currentAccountRoute;
    }

    async getAccounts(): Promise<Account[]> {
        const user = await this.user;
        if (user) {
            return [...user.accounts];
        } else {
            return [];
        }
    }

    async getAccountName(id: string) {
        return (await this.getAccounts()).find(a => a.id === id).name;
    }

    async getCurrentUser() {
        return this.user ? await this.user : null;
    }

    async getUser(t: any) {
        return this.httpService.get<LoggedInUser>(t, '/core/me/user');
    }

    async setUser(t: any) {
        try {
            this.user = this.httpService.get<LoggedInUser>(t, '/core/me/user');
            return await this.user;
        } catch (e) {
            console.error(e);
        }
    }

    async switchAccount(accountId: string, reload: boolean = true, route?: string) {
        if (accountId === this.getAccountId()) {
            this.httpService.setAccountId(accountId);
            if (route) location.replace(route);
            return;
        }

        const user = await this.user;
        const newAccountIndex = user.accounts.findIndex(x => x.id === accountId);

        if (user.accounts[newAccountIndex]) {
            const account = user.accounts[newAccountIndex];
            await this.setAccount(account);

            if (reload) {
                if (route) {
                    location.replace(route);
                } else {
                    const parts = location.href.split('/');
                    if (parts.length > 5) {
                        if (parts.length > 5 && parts.slice(5).some(x => Utils.hasGuid(x))) location.href = `/a/${this.getAccountRoute()}`;
                        else location.href = `/a/${this.getAccountRoute()}/${parts.slice(5).join('/')}`;
                    } else location.href = `/a/${this.getAccountRoute()}`;
                }
            }

            this.httpService.setAccountId(accountId);

            return await this.getAccount();
        }
        return null;
    }

    async checkCanary() {
        await this.connect();
        const user = await this.getCurrentUser();
        const company = user?.accounts?.find(x => x.id === user.company?.id);
        if (!user?.enabledActions?.includes(PermissionAction.AccessCanary)
            || !company?.enabledActions?.includes(PermissionAction.AccessCanary)) {
            return false;
        }

        if (!environment.version.match(/^[0-9]/) || document.location.host.includes('-canary')) {
            return false;
        }

        try {
            const apiDomain = environment.is360Api;
            const canaryDomain = apiDomain.replace('.weavix', '-canary.weavix');
            const current = await this.http.get<any>(`${apiDomain}/info`).toPromise();
            const canary = await this.http.get<any>(`${canaryDomain}/info`).toPromise();
            const currentTimestamp = current.version.match(/[0-9]+/g).pop();
            const canaryTimestamp = canary.version.match(/[0-9]+/g).pop();
            if (canaryTimestamp > currentTimestamp) {
                return true;
            }
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    async saveAccount(t: any, account: Account) {
        const result = await this.httpService.put<Account>(t, '/account/update', account);

        // Update cached user account
        const user = await this.user;
        const idx = user.accounts.findIndex(x => x.id === result.id);
        user.accounts[idx] = result;
        this.account$.next(result);
    }

    private async getInitialAccount(): Promise<Account> {
        const accounts = await this.getAccounts();
        return this.getAccountBasedOnCookie(accounts);
    }

    private getAccountBasedOnCookie(accounts: Account[]): Account {
        const cookieId = this.getAccountFromCookie(accounts);
        const accountIndex = accounts.findIndex(x => x.id === cookieId);
        return accounts[accountIndex > -1 ? accountIndex : 0];
    }

    private subscribeToAccountFeatures(accountId: string) {
        if (this.accountFeaturesSubscription) {
            this.accountFeaturesSubscription.unsubscribe();
        }

        this.accountFeaturesSubscription = this.pubSubService.subscribe<AccountFeaturesUpdated>(null, Topic.AccountFeaturesUpdated, [accountId]).subscribe((message) => {
            this.features = message.payload.features;
            this.features$.next(this.features);
        });
    }

    setAccountCookie(accountId: string) {
        const currentCookieAccountId = this.getAccountFromCookie();
        if (currentCookieAccountId !== accountId) {
            const domainSplit = location.hostname.split('.').reverse();
            const domain = domainSplit[0] !== 'localhost' ? `.${domainSplit[1]}.${domainSplit[0]}` : null;
            const secure = domain != null;
            const expiresDays = 365;
            this.cookieService.set(AccountService.ACCOUNT_ID_COOKIE_KEY, accountId, expiresDays, '/', domain, secure, 'Strict');
        }
    }

    private getAccountFromCookie(accounts?: Account[]): string {
        return Utils.getAccountId(accounts) || this.cookieService.get(AccountService.ACCOUNT_ID_COOKIE_KEY);
    }

    selfRegisterEmailValidation(component, token: string, body: { email: string; phone?: string }) {
        return this.httpService.post<EmailValidationResult>(component, `/account/facility-register/validate-email/${token}`, body);
    }

    selfRegister(component, token: string, selfRegister: SelfRegisterNewUserPersonRequest) {
        return this.httpService.post<Person>(component, `/account/facility-register/${token}`, selfRegister);
    }

    existingSelfRegister(component, token: string, update: PersonUpdate) {
        return this.httpService.post<Person>(component, `/account/existing-facility-register/${token}`, update);
    }

    changeLoginCredentials(component, value: string, loginType: LoginType, userId: string) {
        return this.httpService.post<void>(component, `/account/change-login`, { value, loginType, userId });
    }

    getShortCodeData(component, shortCode: string) {
        return this.httpService.get<ShortCodeData>(component, `/account/short-code/${shortCode}`);
    }

    getAccountFromStore(accountId: string) {
        return companiesContext.get().get(accountId);
    }
}
